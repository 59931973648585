var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "components-layout-demo-custom-trigger" },
    [
      _c(
        "a-layout-sider",
        { attrs: { breakpoint: "lg", "collapsed-width": "0" } },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _vm.sor && _vm.sor.file_name
                ? _c("img", { attrs: { id: "logo", src: _vm.sor.file_url } })
                : _c("a-avatar", {
                    staticClass: "user_icon",
                    attrs: { icon: "user" }
                  }),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { xs: { span: 0 }, md: 24 } }, [
                    _vm._v("\n          " + _vm._s(_vm.userName) + "\n        ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu",
            {
              attrs: {
                theme: "dark",
                mode: "inline",
                selectedKeys: _vm.selectedKeys
              },
              on: { select: _vm.menuItemChanged }
            },
            [
              _c(
                "a-menu-item",
                { key: "1" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/dashboard" } },
                    [
                      _c("a-icon", { attrs: { type: "line-chart" } }),
                      _c("span", [_vm._v("Statistiques")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "2" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/adherents" } },
                    [
                      _c("a-icon", { attrs: { type: "usergroup-add" } }),
                      _c("span", [_vm._v("Etat des inscrits")]),
                      _c("a-badge", {
                        attrs: {
                          count: _vm.getAdherentEnAttenteCount,
                          numberStyle: {
                            backgroundColor: "#009688",
                            "margin-left": "15%"
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "3" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/cotisation" } },
                    [
                      _c("a-icon", { attrs: { type: "dollar" } }),
                      _c("span", [_vm._v("Etat des paiements")]),
                      _c("a-badge", {
                        attrs: {
                          count: _vm.newPaiementsCount,
                          numberStyle: {
                            backgroundColor: "#009688",
                            "margin-left": "15%"
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "4" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/attestations" } },
                    [
                      _c("a-icon", { attrs: { type: "copy" } }),
                      _c("span", [_vm._v("Etat des attestations")]),
                      _c("a-badge", {
                        attrs: {
                          count: _vm.demandeAttestationsCount,
                          numberStyle: {
                            backgroundColor: "#009688",
                            "margin-left": "15%"
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "5" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/demandes" } },
                    [
                      _c("a-icon", { attrs: { type: "form" } }),
                      _c("span", [_vm._v("Liste des demandes")]),
                      _c("a-badge", {
                        attrs: {
                          count: _vm.demandeOuverturesCount,
                          numberStyle: {
                            backgroundColor: "#009688",
                            "margin-left": "15%"
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-sub-menu",
                { key: "6" },
                [
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c("a-icon", { attrs: { type: "setting" } }),
                      _c("span", [_vm._v("Configuration")])
                    ],
                    1
                  ),
                  _c(
                    "a-sub-menu",
                    { key: "7" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "title" }, slot: "title" },
                        [
                          _c("a-icon", { attrs: { type: "highlight" } }),
                          _c("span", [_vm._v("Conventions")])
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "8" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/type_attestations" } },
                            [
                              _c("a-icon", { attrs: { type: "file-add" } }),
                              _c("span", [_vm._v("Type des attestations")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "9" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/organismes" } },
                            [
                              _c("a-icon", { attrs: { type: "home" } }),
                              _c("span", [_vm._v("Organismes")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-sub-menu",
                    { key: "10" },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "title" }, slot: "title" },
                        [
                          _c("a-icon", { attrs: { type: "dollar" } }),
                          _c("span", [_vm._v("Paiements")])
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "11" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/exercices" } },
                            [
                              _c("a-icon", { attrs: { type: "calendar" } }),
                              _c("span", [_vm._v("Exercices")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-menu-item",
                        { key: "12" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/type_paiements" } },
                            [
                              _c("a-icon", { attrs: { type: "euro" } }),
                              _c("span", [_vm._v("Type des paiements")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "13" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/dossier_requis" } },
                        [
                          _c("a-icon", { attrs: { type: "folder-open" } }),
                          _c("span", [_vm._v("Dossiers requis")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "14" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/type_demandes" } },
                        [
                          _c("a-icon", { attrs: { type: "file-add" } }),
                          _c("span", [_vm._v("Type des demandes")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "15" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/rapports" } },
                        [
                          _c("a-icon", { attrs: { type: "file-pdf" } }),
                          _c("span", [_vm._v("Rapports")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "16" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/messages-communications" } },
                        [
                          _c("a-icon", { attrs: { type: "notification" } }),
                          _c("span", [_vm._v("Messages communications")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "17" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/page-accueil" } },
                        [
                          _c("a-icon", { attrs: { type: "notification" } }),
                          _c("span", [_vm._v("Page Accueil")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "18" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/organisation/edit" } },
                        [
                          _c("a-icon", { attrs: { type: "home" } }),
                          _c("span", [_vm._v("Organisation")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-menu-item",
                    { key: "19" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/journal" } },
                        [
                          _c("a-icon", { attrs: { type: "solution" } }),
                          _c("span", [_vm._v("Journal d'utilisation")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "20" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/deleted-users" } },
                    [
                      _c("a-icon", { attrs: { type: "delete" } }),
                      _c("span", [_vm._v("Les adhérents supp.")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "21" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/messagerie" } },
                    [
                      _c("a-icon", { attrs: { type: "inbox" } }),
                      _c("span", [_vm._v("Messagerie")]),
                      _c("a-badge", {
                        attrs: {
                          count: _vm.unreadMsgCount,
                          numberStyle: {
                            backgroundColor: "#009688",
                            "margin-left": "15%"
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "22" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/login" } },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _c("span", [_vm._v("Déconnexion")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-content",
            { staticClass: "content_layout" },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }